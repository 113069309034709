// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = (props: PageProps) => (
  <Layout>
    <SEO title="Privacy Policy" />
    <h1>No Privacy</h1>
    <p>This site does not make any guarantees about your privacy.  It might certainly make use of tracking cookies or other tracking mechanisms, and traffic and or data on this site may be distributed publicly at the owner's discression.</p>
  </Layout>
)

export default PrivacyPolicy
